import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faDollarSign, faStamp, faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import {Button} from 'react-bootstrap';
import history from '../history';
import "../App.css";

const Services1 = () => {
    return (
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="box2">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faBookOpen} size="2x" /></div>
                    <h3>Bookkeeping</h3>
                    <h3>Contabilidad</h3>
                    <p>Permítanos cuidar de su negocio. 
                    En MMS ofrece servicios personalizados de contabilidad para satisfacer las necesidades de nuestros clientes. 
                    Ofrecemos resúmenes mensuales o trimestrales para clientes de pequeñas empresas que necesitan servicios regulares de contabilidad, 
                    incluidas conciliaciones bancarias y preparación de estados financieros...</p><br></br>
                    <Button variant="btn btn-success" onClick={() => history.push('/Bookkeeping')}>Leer Más</Button>
                </div>
              </div>
              {/* - */}
              <div className="col-md-3 col-sm-6">
                <div className="box2">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faClipboardCheck} size="2x" /></div>
                  <h3>Payroll /</h3>
                  <h3>Nomina</h3>
                  <p>En el procesamiento de nómina, los propietarios de pequeñas empresas pueden pasar muchas horas pagando a los empleados y cumpliendo con las leyes estatales y federales. 
                  Podemos proporcionar soluciones de nómina para satisfacer las necesidades de su negocio y asegurar que seguirá cumpliendo con las normas y requisitos de nómina en constante cambio...</p><br></br>
                  <Button variant="btn btn-success" onClick={() => history.push('/Payroll')}>Leer Más</Button>
                </div>
              </div>
              {/* - */}
              <div className="col-md-3 col-sm-6">
                <div className="box2">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faDollarSign} size="2x" /></div>
                  <h3>Planificación</h3> 
                  <h3>Tributaria</h3>
                    <p>La planificación tributaria incluye el análisis desde el punto de vista fiscal y financiera de cada persona para establecer un plan a su medida para garantizar que todos los elementos trabajen juntos para lograr una eficiencia fiscal. 
                    Contamos con profesionales capacitados para brindarte un excelente servicio...</p><br></br><br></br><br></br>
                  <Button variant="btn btn-success" onClick={() => history.push('/Planning')}>Leer Más</Button>
                </div>
              </div>
              {/* - */}
              <div className="col-md-3 col-sm-6">
                <div className="box2">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faStamp} size="2x" /></div>
                  <h3>Notario</h3>
                  <h3>Público</h3>
                  <p>Somos su mejor opción para notarizar, legalizar o apostillar sus documentos importantes...</p><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                  <Button variant="btn btn-success" onClick={() => history.push('/Notary')}>Leer Más</Button>
                  </div>
              </div>
            </div>
          </div>
        </div>
    )

  }
export default Services1