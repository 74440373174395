import React from "react"
import myself from "../images/accounting.jpeg";

const AboutUs = () => {
    return (
        <div className="about-us">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="photo-wrap mb-5">
                            <img className="profile-img" src={myself} alt="myself..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="about-heading">Quienes somos</h1>
                        <p>
                        En Magaly’s Multi Services (MMS) nos dedicamos a brindar servicios de contabilidad e impuestos de calidad a individuos y empresas en el área de Florida Central.
                        </p>
                        <p>
                        Permita que MMS con nuestra experiencia prepare su declaración de impuestos.
                        </p>
                        <p>
                        Nuestro objetivo es construir una relación a largo plazo en la que pueda confiar cuando necesite asistencia profesional con un enfoque personal y oportuno.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs