import React from "react"
import Notaryp from "../images/Notary.jpeg";

const Notary = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={Notaryp} alt="liability..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Notario Público</h1>
                        <p>
                        Somos su mejor opción para notarizar, legalizar o apostillar sus documentos importantes.
                        </p>
                        <br></br>
                        <p>Como Notario Público, actuamos como un testigo imparcial en la firma de documentos y la verificación de los hechos de los documentos en un certificado notarial. 
                        Al ejecutar nuestras funciones, los notarios ayudan a disuadir el fraude y promueven la integridad de la transacción del documento.</p>
                        <br></br>
                        <p>Hacemos verificación de identidad, certificación de una copia fiel de los documentos originales, toma de declaraciones juradas y declaraciones legales, administración de juramentos y afirmaciones, toma de reconocimientos de escrituras y otros traspasos, entrega de copias notariales, 
                        atestiguar y autenticar la ejecución de ciertas clases de documentos. , y realizar ciertos otros actos formales relacionados con el cargo de Notario Público. 
                        Como representantes formales del estado, respaldamos la correcta ejecución de muchos de los documentos que cambian la vida, ya sea que estas transacciones transfieran bienes raíces, otorguen poderes, establezcan un acuerdo prematrimonial o realicen varias otras actividades 
                        que permitan o permitan la operación de nuestra sociedad civil.</p>
                        <br></br>
                        <h3>Nuestros Servicios incluyen</h3>
                        <p>&#9642; Servicio de Notarización y Apostille.</p>
                        <p>&#9642; Notarización de Escrituras.</p>
                        <p>&#9642; Servicio de Boda a Domicilio.</p>
                        <p>&#9642; Servicio de Traducción de Documentos.</p>
                        <p>&#9642; Preparación de Formas de Inmigración.</p>
                        <p>&#9642; Autorización de Viaje de Menores.</p>
                        <p>&#9642; Contratos.</p>
                        <p>&#9642; Fideicomisos.</p>
                        <p>&#9642; Testamentos.</p>
                        <br></br>
                        <h3>¿Cómo identifica un notario a un firmante?</h3>
                        <p>Identificamos a un firmante solicitando ver una identificación actual con una foto, descripción física y firma, generalmente los documentos aceptados incluyen su licencia de conducir o pasaporte.</p>
                        <br></br>
                        <h3>Lo que no es un notario.</h3>
                        <p>Un Notario Público de los Estados Unidos no es un abogado, juez o funcionario de alto rango. Un notario estadounidense no es lo mismo que un notario público y estas variaciones pueden ser confusas para algunos inmigrantes. 
                        Los notarios en los Estados Unidos deben ser muy claros sobre lo que pueden o no hacer para servir a los inmigrantes de la manera correcta.</p>
                        <br></br>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notary;