import React from "react"
import Individual from "../images/1040.jpeg";

const Individuals = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={Individual} alt="individuals..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Taxes Personales</h1>
                        <p>
                        En MMS queremos ayudarles con su declaración de impuestos de manera segura y sin errores. Con nuestra experiencia, esforzamos por preparar su declaración de impuestos y así obtener la mayor devolución de impuestos según sus necesidades.
                        Deje sus impuestos en nuestras manos y agende una cita online o presencial.
                        </p>
                        <h3>Quiénes deben presentar una declaración de impuestos?</h3>
                        <p>&#9642; Los extranjeros no residentes solo están obligados a presentar una declaración de impuestos en Estados Unidos si obtuvieron ingresos en este país.</p>
                        <p>&#9642; Los ciudadanos estadounidenses y residentes en Estados Unidos deben presentar una declaración de impuestos para reportar sus ingresos dentro y fuera del país, si el monto supera la deducción estándar permisible.</p>
                        <p>&#9642; Los inmigrantes indocumentados también están obligados a presentar una declaración de impuestos y a pagar tributos. Esto puede ser favorable para un individuo tratando de obtener estatus legal, en este caso para declarar sus impuestos, deben obtener un Tax ID o ITIN.</p>
                        <br></br>
                        <h3>Qué ingresos debo reportar en mi declaración de impuestos?</h3>
                        <p>&#9642; Salarios de empleo y otros servicios, registrados en un formulario W2 o pagados en efectivo.</p>
                        <p>&#9642; Intereses de cuentas bancarias, inversiones y préstamos personales.</p>
                        <p>&#9642; Dividendos de cuentas de inversión.</p>
                        <p>&#9642; Ganancias en Criptomonedas.</p>
                        <p>&#9642; Propinas.</p>
                        <p>&#9642; Ganancias de juego.</p>
                        <p>&#9642; Ganancias en la venta de propiedades.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Individuals;