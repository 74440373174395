import React from "react"
import corporates from "../images/1120.jpeg";

const Corporate = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={corporates} alt="liability..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Taxes de Negocios</h1>
                        <p>
                        Todas las corporaciones sin importar su clasificación (C,S,LLC), deben presentar una declaración anual sobre los ingresos, contar con un experto en la materia es la mejor alternativa para usted. 
                        Deje sus impuestos corporativos en nuestras manos y agende una cita online o presencial
                        </p>
                        <p>MMS brinda una gama completa de servicios de impuestos a varios tipos de empresas cerradas, incluidas las corporaciones S, corporaciones C, LLC, sociedades y entidades no incorporadas. 
                        Ofrecemos servicios de planificación fiscal para ayudar a identificar estrategias de ahorro fiscal. Durante la preparación de impuestos, nos esforzamos por preparar las declaraciones más precisas e implementar varios procedimientos 
                        de control de calidad para evitar errores y mitigar posibles consultas gubernamentales. Además, nuestro personal amable y bien informado está disponible para analizar cuestiones fiscales y contables y ayudar con los ajustes contables de fin de año que sean necesarios. 
                        Nos esforzamos por brindarles a los dueños de negocios tranquilidad en relación con su situación fiscal para que pasen más tiempo construyendo su negocio.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Corporate;