import React from "react"
import Plan from "../images/Planning.jpeg";

const Planning = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={Plan} alt="liability..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Planificación Tributaria</h1>
                        <p>
                        La planificación tributaria incluye el análisis desde el punto de vista fiscal y financiera de cada persona para establecer un plan a su medida para garantizar que todos los elementos trabajen juntos para lograr una eficiencia fiscal. 
                        Contamos con profesionales capacitados para brindarte un excelente servicio. La planificación fiscal debe ser una parte esencial del plan financiero de cualquier individuo o grupo familiar individual. 
                        La reducción de la obligación tributaria y la maximización de la capacidad de contribuir a los planes de jubilación son cruciales para el éxito.
                        </p>
                        <br></br>
                        <h3>Puntos Claves:</h3>
                        <p>&#9642; La planificación fiscal analiza la situación financiera o conforma plan para garantizar que todos los elementos trabajen juntos para permitirle pagar los impuestos más bajos posibles.</p>
                        <p>&#9642; Las consideraciones de la planificación fiscal incluyen el momento de los ingresos, el tamaño, el momento de las compras y la planificación de los gastos.</p>
                        <p>&#9642; Las estrategias de planificación fiscal pueden incluir ahorrar para la jubilación en una IRA o participar en la recolección de ganancias y pérdidas fiscales.</p>
                        <br></br>
                        <h3>
                        Comprender la planificación fiscal
                        </h3>
                        <p>
                        La planificación fiscal abarca varias consideraciones. Las consideraciones incluyen el momento de los ingresos, el tamaño y el momento de las compras, y la planificación de otros gastos. 
                        Además, la selección de inversiones y tipos de planes de jubilación debe complementar el estado civil para efectos de la declaración de impuestos y las deducciones para crear el mejor resultado posible.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Planning;