import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import NewCompany from "../src/pages/NewCompany";
import Individuals from "../src/pages/Individuals";
import Corporate from "../src/pages/Corporate";
import Itin from "../src/pages/ITIN";
import history from './history';

export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/NewCompany" component={NewCompany} />
                    <Route path="/Individuals" component={Individuals} />
                    <Route path="/Corporate" component={Corporate} />
                    <Route path="/Itin" component={Itin} />
                </Switch>
            </Router>
        )
    }
}
