import React from "react";
import Company from "../images/NewCompany.jpeg";

const NewCompany = () => {
  return (
    <div className="pages">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xm-12">
            <div className="pages-photo-wrap mb-5">
              <img
                className="pages-profile-img"
                src={Company}
                alt="newcompany..."
              />
            </div>
          </div>
          <div className="col-lg-6 col-xm-12">
            <h1 className="pages-heading">Registro de Nuevas Empresas</h1>
            <h3>&#9642; Empresas de Responsabilidad Limitada o LLC:</h3>
            <p>
              Una Compañía de Responsabilidad Limitada (LLC, por sus siglas en
              inglés) es una estructura de negocio permitido conforme a los
              estatutos estatales. Cada estado puede utilizar regulaciones
              diferentes y usted debe verificar con su estado si está interesado
              en iniciar una Compañía de Responsabilidad Limitada. Los dueños de
              las LLC se llaman miembros. La mayoría de los estados no
              restringen la propiedad, por lo que los miembros pueden incluir
              individuos, sociedades anónimas, otras LLC y entidades
              extranjeras. No hay un límite máximo de miembros. La mayoría de
              los estados también permiten las LLC de un “miembro único”, las
              que tienen un solo dueño.
            </p>
            <h3>&#9642; Sociedades o Partnership:</h3>
            <p>
              Una sociedad colectiva es la relación que existe entre dos o más
              personas que se unen para llevar a cabo un comercio o negocio.
              Cada persona aporta dinero, propiedad, labor, o destrezas, y
              espera compartir las ganancias y las pérdidas del negocio. Una
              sociedad colectiva debe presentar una declaración informativa
              anual para declarar los ingresos, deducciones, ganancias,
              pérdidas, etcétera, de sus operaciones, pero eso no paga el
              impuesto sobre los ingresos. En su lugar, “pasa” toda ganancia o
              pérdida a sus socios. Cada socio incluye su parte de los ingresos
              o pérdidas de la sociedad colectiva en su declaración de impuestos
              personales. Los socios no son empleados y no se les debe emitir un
              Formulario W-2. La sociedad colectiva debe proporcionar copias del
              Anexo K-1 (del Formulario 1065) a los socios para la fecha que se
              requiere presentar el Formulario 1065, incluidas las prórrogas.
            </p>
            <h3>&#9642; Corporación o Corp</h3>
            <p>
              Al formar una sociedad anónima, los accionistas potenciales
              intercambian dinero, propiedad, o ambos, para el capital social de
              la sociedad anónima. Una sociedad anónima generalmente toma las
              mismas deducciones que un dueño único para calcular sus ingresos
              tributables. Una sociedad anónima también puede tomar deducciones
              especiales. Para los propósitos del impuesto federal sobre los
              ingresos, una sociedad anónima de tipo C se reconoce como una
              entidad contribuyente separada. Una sociedad anónima lleva a cabo
              operaciones, realiza ingresos o pérdidas netas, paga impuestos y
              distribuye las ganancias a los accionistas. Los impuestos sobre la
              ganancia de una sociedad anónima se imponen al momento de percibir
              la ganancia, y luego el impuesto se impone a los accionistas
              cuando es distribuida como dividendos. Esto resulta en un impuesto
              doble. La sociedad anónima no recibe una deducción tributaria al
              distribuir los dividendos a los accionistas. Los accionistas no
              pueden deducir ninguna pérdida de la sociedad anónima.
            </p>
            <h3>&#9642; Corporación S o S Corp</h3>
            <p>
              Las sociedades anónimas de tipo S son sociedades anónimas que
              optan por pasar los ingresos, pérdidas, deducciones y créditos a
              través de sus accionistas para los propósitos del impuesto
              federal. Los accionistas de las sociedades anónimas de tipo S
              declaran el flujo de ingresos y pérdidas en sus declaraciones de
              impuestos personales y se les imponen los impuestos a las tasas
              impositivas sobre sus ingresos personales. Esto permite a las
              sociedades anónimas de tipo S evitar el doble impuesto sobre los
              ingresos de la sociedad anónima. Las sociedades anónimas de tipo S
              son responsables del impuesto sobre ciertas ganancias incorporadas
              y los ingresos pasivos al nivel de la entidad. Para reunir los
              requisitos para el estado de sociedad anónima de tipo S, la
              sociedad anónima tiene que cumplir los siguientes requisitos.
            </p>
            <p>&#x27A4; Ser una sociedad anónima doméstica.</p>
            <p>
              &#x27A4; Tener sólo accionistas permitidos o Pueden ser
              individuos, ciertos fideicomisos y caudales hereditarios, y o No
              pueden ser sociedades colectivas, sociedades anónimas, o
              accionistas extranjeros residentes.
            </p>
            <p>&#x27A4; No tener más de 100 accionistas.</p>
            <p>
              &#x27A4; No ser una sociedad anónima inelegible (o sea, ciertas
              instituciones financieras, compañías de seguros y sociedades
              anónimas domésticas de ventas internacionales).
            </p>
            <p>
              Solo tener una clase de acción Para convertirse en una sociedad
              anónima de tipo S, la sociedad anónima debe presentar el
              Formulario 2553, Election by a Small Business Corporation firmado
              por todos los accionistas.
            </p>
            <h3>&#9642; Empresario por Cuenta Propia O Sole Proprietorship</h3>
            <p>
              Un empresario por cuenta propia es alguien que posee un negocio no
              incorporado por sí mismo. Sin embargo, si usted es el único
              miembro de una compañía de responsabilidad limitada (LLC, por sus
              siglas en inglés), no es un empresario por cuenta propia si elige
              tratar a la LLC como una corporación.
            </p>
          </div>
        </div>
      </div>
      <div className="services">
        <h1 className="py-5">TABLA COMPARATIVA DE CORPORACIONES</h1>
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="box4">
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box4">
                <h3>LLC</h3>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box4">
                <h3>S Corp</h3>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box4">
                <h3>Corp o INC</h3>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
              <h4>¿Quién es responsable ante terceros?</h4>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Los miembros no son responsables de los actos de la entidad.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Los miembros no son responsables de los actos de la entidad.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Los miembros no son responsables de los actos de la entidad.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
              <h4>Requisitos</h4>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Pocos requisitos, dependiendo del estado donde se incorporen.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Nombrar a la Junta Directiva, y esto designa al Presidente, las reuniones anuales y la presentación del informe anual son requisitos.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Nombramiento de la Junta Directiva, y esto designa al Presidente, las reuniones anuales, y la presentación del informe anual son requisitos.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
              <h4>Administración</h4>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Los miembros directivos llevan la representación legal de la entidad.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>El presidente lleva la representación legal de la entidad.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>El presidente lleva la representación legal de la entidad.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
              <h4>Tiempo de vida</h4>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Perpetuo, a menos que el estado requiera un tiempo fijo.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Perpetuo, se puede extender después de la muerte o la jubilación de los accionistas.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>A perpetuidad, puede extenderse después del fallecimiento o retiro de los accionistas.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
              <h4>Impuestos</h4>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>No hay impuestos a nivel de entidad. Los ingresos se gravan directamente a los miembros, incluso si no reciben una distribución de ganancias.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>No hay impuesto a nivel de entidad. Los ingresos se gravan directamente a los accionistas, aunque no reciben una distribución de beneficios.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Tributo a nivel de entidad y sujeto a doble tributación ya que los dividendos se gravan a nivel individual si se distribuyen a los accionistas.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
              <h4>Pago de impuestos por empleo</h4>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Los miembros generalmente no reciben salarios (W2). Su remuneración se considera como ingreso laboral.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Salario sujeto al impuesto sobre el empleo (recibe W2), pero los dividendos pagados a los accionistas no están sujetos al impuesto sobre el empleo.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Salario sujeto al impuesto sobre el empleo (recibe W2), pero los dividendos pagados a los accionistas no están sujetos al impuesto sobre el empleo.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
              <h4>Annual Tax Return</h4>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Ingresos en el Formulario 1065 (asociación con al menos 2 socios) Reportar la distribución de los beneficios a los miembros a través de K-1.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Reporte la distribución de beneficios a los miembros a través de los ingresos y gastos K-1 en el Formulario 1120S.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Ingresos y gastos en el formulario 1120.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
              <h4>Annual report to the State</h4>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Si, antes del 1 de mayo.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Si, antes del 1 de mayo.</p>
              </div>
            </div>
            {/* - */}
            <div className="col-md-3 col-sm-6">
              <div className="box3">
                <p>Si, antes del 1 de mayo.</p>
              </div>
            </div>
            {/* - */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCompany;
