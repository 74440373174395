import React from "react"
import Pay from "../images/Payroll.jpeg";

const Payroll = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={Pay} alt="liability..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Payroll</h1>
                        <p>
                        En el procesamiento de nómina, los propietarios de pequeñas empresas pueden pasar muchas horas pagando a los empleados y cumpliendo con las leyes estatales y federales. 
                        Podemos proporcionar soluciones de nómina para satisfacer las necesidades de su negocio y asegurar que seguirá cumpliendo con las normas y requisitos de nómina en constante cambio.
                        Viva sin preocupaciones con nuestro servicio de nómina. Realizamos el procesamiento de nómina semanal, quincenal, quincenal y mensual.
                        </p>
                        <br></br>
                        <h3>Los impuestos sobre la nómina incluyen los siguientes:</h3>
                        <p>&#9642; Los impuestos al Seguro Social y al Medicare.</p>
                        <p>&#9642; La retención del impuesto federal sobre los ingresos.</p>
                        <p>&#9642; El impuesto federal sobre el desempleo.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payroll;