import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard, faClipboardList, faCalculator, faBriefcase } from "@fortawesome/free-solid-svg-icons";
import {Button} from 'react-bootstrap';
import history from './../history';
import "../App.css";

const Services = () => {
    return (
        <div className="services">
          <h1 className="py-5">nuestros servicios</h1>
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="box">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faClipboardList} size="2x" /></div>
                    <h3>Registro de Nuevas</h3>
                    <h3>Empresas</h3>
                    <p>Con nuestra ayuda profesional podemos ayudarte con el trámite de abrir tu idea de un nuevo negocio. En MMS te asesoramos y nos encargamos de todos los trámites necesarios. 
                    Contáctanos, para una asesoría e iniciar el proceso de apertura de empresa y disfruta de estos beneficios...</p><br></br><br></br>
                    <Button variant="btn btn-success" onClick={() => history.push('/NewCompany')}>Leer Más</Button>
                </div>
              </div>
              {/* - */}
              <div className="col-md-3 col-sm-6">
                <div className="box">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faCalculator} size="2x" /></div>
                    <h3>Taxes</h3>
                    <h3>Personales</h3>
                    <p>En MMS queremos ayudarles con su declaración de impuestos de manera segura y sin errores. 
                    Con nuestra experiencia, esforzamos por preparar su declaración de impuestos y así obtener la mayor devolución de impuestos según sus necesidades. 
                    Deje sus impuestos en nuestras manos y agende una cita online o presencial....</p>
                    <Button variant="btn btn-success" onClick={() => history.push('/Individuals')}>Leer Más</Button>
                </div>
              </div>
              {/* - */}
              <div className="col-md-3 col-sm-6">
                <div className="box">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faBriefcase} size="2x" /></div>
                  <h3>Taxes de</h3>
                  <h3>Negocios</h3>
                  <p>Todas las corporaciones sin importar su clasificación (C,S,LLC), deben presentar una declaración anual sobre los ingresos,
                  contar con un experto en la materia es la mejor alternativa para usted.
                  Deje sus impuestos corporativos en nuestras manos y agende una cita online o presencial...</p><br></br><br></br>
                      <Button variant="btn btn-success" onClick={() => history.push('/Corporate')}>Leer Más</Button>
                </div>
              </div>
              {/* - */}
              <div className="col-md-3 col-sm-6">
                <div className="box">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faIdCard} size="2x" /></div>
                  <h3>Taxes ID /</h3>
                  <h3>ITIN</h3>
                  <p>El ITIN (Tax ID) o Número de Identificación Personal del Contribuyente es un número de tramitación de impuestos emitido por el Servicio de Impuestos Internos. 
                  Aclararemos sus dudas y le ayudaremos a obtener su Tax ID de manera segura y rápida...</p><br></br><br></br>
                  <Button variant="btn btn-success" onClick={() => history.push('/Itin')}>Leer Más</Button>
                  </div>
              </div>
              {/* - */}
              {/* <div className="col-md-3 col-sm-6">
                <div className="box2">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faCar} size="2x" /></div>
                    <h3>Bookkeeping</h3>
                    <p>Permítanos cuidar de su negocio. 
                    En MMS ofrece servicios personalizados de contabilidad para satisfacer las necesidades de nuestros clientes. 
                    Ofrecemos resúmenes mensuales o trimestrales para clientes de pequeñas empresas que necesitan servicios regulares de contabilidad, 
                    incluidas conciliaciones bancarias y preparación de estados financieros...</p>
                    <Button variant="btn btn-success" onClick={() => history.push('/Auto')}>Leer Más</Button>
                </div>
              </div> */}
              {/* - */}
              {/* <div className="col-md-3 col-sm-6">
                <div className="box2">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faHouseDamage} size="2x" /></div>
                  <h3>Payroll</h3>
                  <p>Viva sin preocupaciones con nuestro servicio de nómina. Realizamos el procesamiento de nómina semanal, quincenal, quincenal y mensual, o trabajamos con cualquier horario que se adapte a sus necesidades. 
                  Brindamos cheques y depósito directo, y puede transmitir su nómina en línea, por fax o por correo electrónico...</p><br></br>
                    <Button variant="btn btn-success" onClick={() => history.push('/Other')}>Leer Más</Button>
                </div>
              </div> */}
              {/* - */}
              {/* <div className="col-md-3 col-sm-6">
                <div className="box2">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faBuilding} size="2x" /></div>
                  <h3>Planificación Tributaria</h3>
                    <p>La planificación tributaria incluye el análisis desde el punto de vista fiscal y financiera de cada persona para establecer un plan a su medida para garantizar que todos los elementos trabajen juntos para lograr una eficiencia fiscal. 
                    Contamos con profesionales capacitados para brindarte un excelente servicio...</p><br></br><br></br>
                  <Button variant="btn btn-success" onClick={() => history.push('/Home')}>Leer Más</Button>
                </div>
              </div> */}
              {/* - */}
              {/* <div className="col-md-3 col-sm-6">
                <div className="box2">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faClipboardList} size="2x" /></div>
                  <h3>Notario Público</h3>
                  <p>Somos su mejor opción para notarizar, legalizar o apostillar sus documentos importantes...</p><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                  <Button variant="btn btn-success" onClick={() => history.push('/Commercial')}>Leer Más</Button>
                  </div>
              </div> */}
        
            </div>
          </div>
        </div>
    )

  }
export default Services