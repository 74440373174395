import React from "react"
import taxid from "../images/Itin.jpeg";

const Itin = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={taxid} alt="taxid..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Taxes ID / ITIN</h1>
                        <p>
                        El ITIN (Tax ID) o Número de Identificación Personal del Contribuyente es un número de tramitación de impuestos emitido por el Servicio de Impuestos Internos. 
                        El IRS emite los ITIN a las personas a quienes se les requiere tener un número de identificación del contribuyente de los Estados Unidos, pero que no tienen ni reúnen los requisitos para obtener un número de Seguro Social de la Administración de Seguro Social.
                        </p>
                        <br></br>
                        <h3>¿Por qué solicitar un ITIN?</h3>
                        <p>El IRS emite los ITIN para ayudar a la gente a cumplir con las leyes tributarias de los Estados Unidos y para proporcionar a la gente que no reúne los requisitos para obtener un número de Seguro Social, una manera eficiente de tramitar y dar cuenta de sus declaraciones de impuestos y sus pagos. 
                        Se emiten independientemente del estado migratorio, ya que tanto los extranjeros residentes como los no residentes pueden verse obligados a presentar una declaración de impuestos o una declaración informativa de los Estados Unidos, conforme al Código de Impuestos Internos. 
                        Los ITIN no sirven para ningún otro propósito que no sea la presentación de declaraciones del impuesto federal.
                        </p>
                        <br></br>
                        <h3>Un ITIN:</h3>
                        <p>&#9642;No Autoriza a trabajar en los Estados Unidos</p>
                        <p>&#9642;Proporciona el derecho a recibir los beneficios de Seguro Social</p>
                        <p>&#9642;Califica a un dependiente para propósitos del crédito por ingreso del trabajo</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Itin;