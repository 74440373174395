import React from "react"
import Book from "../images/Bookkeeping.jpeg";

const Bookkeeping = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={Book} alt="liability..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Bookkeeping</h1>
                        <p>
                        En MMS ofrece servicios personalizados de contabilidad para satisfacer las necesidades de nuestros clientes. Ofrecemos resúmenes mensuales o trimestrales para clientes de pequeñas empresas que necesitan 
                        servicios regulares de contabilidad, incluidas conciliaciones bancarias y preparación de estados financieros. A través del Bookkeeping se asegura de una forma segura el poder llevar un récord de tus transacciones financieras 
                        (tales como compras, ventas, recibos y pagos) como parte del proceso de la contabilidad de tu empresa.
                        <br></br>
                        </p>
                        <p>Si necesitas una de asesoría acerca de la contabilidad para su empresa con gusto podremos ayudarlo.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bookkeeping;