import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import Bookkeeping from "./pages/Bookkeeping";
import Payroll from "./pages/Payroll";
import Planning from "./pages/Planning";
import Notary from "./pages/Notary";
import history from './history';

export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/Bookkeeping" component={Bookkeeping} />
                    <Route path="/Payroll" component={Payroll} />
                    <Route path="/Planning" component={Planning} />
                    <Route path="/Notary" component={Notary} />
                </Switch>
            </Router>
        )
    }
}
